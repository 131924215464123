import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import FullPageSpinner from "./components/FullPageSpinner";
import ScrollToTop from "./components/ScrollToTop";

// Lazy load the components
const Profile = lazy(() => import("./pages/Profile"));
const Login = lazy(() => import("./pages/Login"));
const Cart = lazy(() => import("./pages/Cart"));
const Home = lazy(() => import("./pages/Home"));
const CreateStudyMaterialForm = lazy(() =>
  import("./pages/CreateStudyMaterialForm")
);
const SingleCollege = lazy(() => import("./pages/SingleCollege"));
const Contribute = lazy(() => import("./pages/Contribute"));
const AllColleges = lazy(() => import("./pages/AllColleges"));
const StudyMaterial = lazy(() => import("./pages/StudyMaterial"));
const About = lazy(() => import("./pages/About"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const Contact = lazy(() => import("./pages/Contact"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const ContributionGuide = lazy(() => import("./pages/ContributionGuide"));
const PaymentGuide = lazy(() => import("./pages/PaymentGuide"));
const FAQ = lazy(() => import("./pages/FAQ"));
const SinglePaper = lazy(() => import("./pages/SinglePaper"));
const StudyMaterialResults = lazy(() => import("./pages/StudyMaterialResults"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<FullPageSpinner />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sell-notes" element={<Contribute />} />
            <Route path="/colleges/:collegeUrl" element={<SingleCollege />} />
            <Route
              path="/colleges/:collegeName/subject/:subjectName/material/:materialId"
              element={<SinglePaper />}
            />
            <Route
              path="/colleges/:collegeName/subject/:subjectName/material/:materialTitle/:materialId"
              element={<SinglePaper />}
            />
            <Route path="/all-colleges" element={<AllColleges />} />
            <Route path="/login" element={<Login />} />
            <Route path="/study-material" element={<StudyMaterial />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/mycart" element={<Cart />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/faq" element={<FAQ />} />
            <Route
              path="/study-materials/:collegeSlug/:subjectSlug"
              element={<StudyMaterialResults />}
            />
            <Route
              path="/study-materials/search/:searchTerm"
              element={<StudyMaterialResults />}
            />
            <Route
              path="/study-materials/college/:collegeSlug/search/:searchTerm"
              element={<StudyMaterialResults />}
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/contribution-guide" element={<ContributionGuide />} />
            <Route path="/payment-guide" element={<PaymentGuide />} />
            <Route
              path="/add-study-material"
              element={<CreateStudyMaterialForm />}
            />
            <Route path="/reset-password" element={<ForgotPassword />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
